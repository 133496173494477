<script>

import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: [
    'datapoints',
    'label'
  ],
  mounted () {
    this.renderChart({
      labels: this.datapoints.map(dp => dp._id),
      datasets: [
        {
          label: this.label,
          backgroundColor: '#e2007a',
          data: this.datapoints.map(dp => dp.count)
        }
      ]
    }, {
      responsive: true,
      maintainAspectRatio: false
    })
  }
}
</script>