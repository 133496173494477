<template>
  <div class="main-banner">
    <div class="overlay" v-if="ageGate">
      <h1>Confirm ca am peste 18 ani</h1>
      <div class="options">
        <span class="button" @click="confirmAge(true)">DA</span>
        <span class="button" @click="confirmAge(false)">NU</span>
      </div>
    </div>
    <div class="header" v-if="!ageGate">
      <img src="../assets/logo.svg" alt="" />
    </div>

    <div
      class="formular"
      id="formular"
      style="position: relative"
      v-if="!ageGate"
    >
      <span v-if="error" class="error">{{ error }}</span>

      <input
        id="nume"
        type="text"
        placeholder="Nume si prenume client*"
        v-model.trim="nume"
      />

      <div v-if="$v.promoterName.$dirty">
        <div class="error" v-if="!$v.promoterName.required">
          Câmpul este obligatoriu
        </div>
      </div>
      <input
        id="promoterName"
        type="text"
        placeholder="Nume promoter*"
        v-model.trim="$v.promoterName.$model"
      />

      <div v-if="$v.phone.$dirty">
        <div class="error" v-if="!$v.phone.required">
          Câmpul este obligatoriu
        </div>
        <div
          class="error"
          v-if="!$v.phone.numeric || !$v.phone.minLength || !$v.phone.maxLength"
        >
          Numărul de telefon trebuie să fie valid
        </div>
      </div>
      <input
        id="phone"
        type="text"
        placeholder="Număr de telefon cartelă oferită*"
        v-model.trim="$v.phone.$model"
      />

      <div v-if="$v.currentPhone.$dirty">
        <div class="error" v-if="!$v.currentPhone.required">
          Câmpul este obligatoriu
        </div>
        <div
          class="error"
          v-if="
            !$v.currentPhone.numeric ||
            !$v.currentPhone.minLength ||
            !$v.currentPhone.maxLength
          "
        >
          Numărul de telefon trebuie să fie valid
        </div>
      </div>
      <input
        id="currentPhone"
        type="text"
        placeholder="Număr de telefon uzual*"
        v-model.trim="$v.currentPhone.$model"
      />

      <div v-if="$v.oras.$dirty">
        <div class="error" v-if="!$v.oras.required">
          Câmpul este obligatoriu
        </div>
      </div>
      <select v-model.trim="$v.oras.$model">
        <option value="" disabled="disabled" selected="selected">Oras*</option>
        <option value="ALBA IULIA">ALBA IULIA</option>
        <option value="ARAD">ARAD</option>
        <option value="BACAU">BACAU</option>
        <option value="BOTOSANI">BOTOSANI</option>
        <option value="BRAILA">BRAILA</option>
        <option value="BRASOV">BRASOV</option>
        <option value="BUCURESTI">BUCURESTI</option>
        <option value="BUZAU">BUZAU</option>
        <option value="CALARASI">CALARASI</option>
        <option value="CLUJ">CLUJ</option>
        <option value="CONSTANTA">CONSTANTA</option>
        <option value="CRAIOVA">CRAIOVA</option>
        <option value="DEVA">DEVA</option>
        <option value="DROBETA">DROBETA</option>
        <option value="FOCSANI">FOCSANI</option>
        <option value="GALATI">GALATI</option>
        <option value="IASI">IASI</option>
        <option value="ORADEA">ORADEA</option>
        <option value="PITESTI">PITESTI</option>
        <option value="PLOIESTI">PLOIESTI</option>
        <option value="RESITA">RESITA</option>
        <option value="SATU MARE">SATU MARE</option>
        <option value="SIBIU">SIBIU</option>
        <option value="SUCEAVA">SUCEAVA</option>
        <option value="TARGU MURES">TARGU MURES</option>
        <option value="TARGU JIU">TARGU JIU</option>
        <option value="TIMISOARA">TIMISOARA</option>
        <option value="VALCEA">VALCEA</option>
        <option value="ZALAU">ZALAU</option>
      </select>

      <div v-if="$v.locatie.$dirty">
        <div class="error" v-if="!$v.locatie.required">
          Câmpul este obligatoriu
        </div>
      </div>
      <input
        id="locatie"
        type="text"
        placeholder="Locatie*"
        v-model.trim="$v.locatie.$model"
      />

      <div v-if="$v.activare.$dirty">
        <div class="error" v-if="!$v.activare.required">
          Câmpul este obligatoriu
        </div>
      </div>
      <select v-model.trim="$v.activare.$model">
        <option value="" disabled selected>
          Cartela a fost activată pe loc?
        </option>
        <option value="DA">DA</option>
        <option value="NU">NU</option>
      </select>

      <span
        :class="[activare ? 'selected' : 'notSelected']"
        @click="activare = !activare"
      ></span>
      <br /><br /><br />
      <span :class="[gdpr ? 'selected' : 'notSelected']" @click="gdpr = !gdpr"
        >Am luat la cunoștință de condițiile de prelucrare a datelor cu caracter
        personal*
      </span>
      <div class="error" v-if="!gdpr">*Câmpul este obligatoriu</div>
      <br /><br /><br />
      <span :class="[tc ? 'selected' : 'notSelected']" @click="tc = !tc"
        >Sunt de acord sa primesc comunicari comerciale care pot contine oferte
        cu privire la produsele si serviciile Telekom Romania Mobile, mesaje cu
        privire la participare ala evenimente si activitati organizate de
        Telekom Romania Mobile sau informari menite sa promoveze imaginea
        Telekom Romania Mobile. Pentru retragerea consimstamantului am fost
        informat ca pot transmite o solicitare prin e-mail catre
        info_tkrm@telkom.ro sau prin apel la 1234 si/sau 0766121234 .</span
      >
      <VueSignaturePad
        id="signature"
        width="300px"
        height="300px"
        ref="signaturePad"
        v-if="tc"
      />
      <div v-if="tc">
        <button class="button" @click="save">Salveaza</button>
        <button class="button" @click="undo">Sterge</button>
      </div>

      <a class="button" @click="submitData()">INSCRIE-TE</a>

      <!-- <a href="/Regulament.pdf" class="button">Regulament</a> -->
    </div>
  </div>
</template>

<script>
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import Axios from "axios";

export default {
  data() {
    return {
      nume: "",
      phone: "",
      currentPhone: "",
      activare: "",
      promoterName: "",
      error: null,
      tc: false,
      gdpr: false,
      menu: false,
      ageGate: true,
      loaded: false,
      oras: "",
      locatie: "",
      semnatura: "",
    };
  },
  validations: {
    phone: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    currentPhone: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    activare: {
      required,
    },
    oras: {
      required,
    },
    locatie: {
      required,
    },
    promoterName: {
      required,
    },
  },
  methods: {
    async submitData() {
      this.$v.$touch();
      if (
        !this.$v.$invalid &&
        this.gdpr &&
        ((this.tc && this.semnatura) || !this.tc)
      ) {
        let coord;
        try {
          coord = await this.$getLocation({ enableHighAccuracy: true });
        } catch (error) {
          console.log(
            "Te rugam sa accepti localizarea GPS din setarile site-ului"
          );
          coord = {
            lat: "Neidentificat",
            lng: "Neidentificat",
          };
        }
        console.log(coord);
        try {
          let file
          if(this.tc && this.semnatura){
            file = this.DataURIToBlob(this.semnatura);
          } else {
            file = null;
          }

          const dataTosubmit = new FormData();
          dataTosubmit.append("nume", this.nume);
          dataTosubmit.append("phoneNumber", this.phone);
          dataTosubmit.append("currentPhone", this.currentPhone);
          dataTosubmit.append("activare", this.activare);
          dataTosubmit.append("promoterName", this.promoterName);
          dataTosubmit.append("oras", this.oras);
          dataTosubmit.append("locatie", this.locatie);
          dataTosubmit.append("lat", coord.lat);
          dataTosubmit.append("lng", coord.lng);
          dataTosubmit.append("semnatura", file);
          dataTosubmit.append("acordMarketing", this.tc);

          const response = await Axios.post(
            "/api/v1/submitEntry",
            dataTosubmit
          );
          this.success = true;
          // console.log(response)
          this.message = response.data.message;
          alert(this.message);
          this.$router.go(this.$router.currentRoute);
          // console.log(response)
        } catch (err) {
          this.success = false;
          this.message = err.response.data.message;
          alert(err.response.data.message);
        }
      } else {
        this.success = false;
        this.message = "Toate campurile sunt obligatorii!";
        alert(this.message);
      }
    },
    confirmAge(age) {
      if (age) {
        this.ageGate = false;
        // this.$cookiebot.consentBanner();
      } else {
        this.$router.push({ name: "AgeGate" });
      }
    },
    logIt(data) {
      console.log("detected", data);
      this.emai = data;
      this.menu = false;
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
      this.semnatura = data;
    },
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    },
  },
  async mounted() {
    try {
      await this.$getLocation({ enableHighAccuracy: true });
    } catch (error) {
      alert("Te rugam sa accepti localizarea GPS din setarile site-ului");
    }
  },
};
</script>

<style lang="sass">
#signature
  background-color: #fff
  border: 1px solid #000
  border-radius: 5px
.overlay
  position: fixed
  height: 110vh
  width: 100vw
  background-color: var(--main-color)
  color: var(--secondary-color)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 10vh 10vw
  box-sizing: border-box

h1
  margin: 0
  margin-bottom: 4vh
  text-align: center

.options
  display: flex
  justify-content: space-around

.main-banner
  width: 100vw
.header
  background-color: var(--main-color)
  display: flex
  height: 15vh
  padding: 2vh 10vw
  box-sizing: border-box
  align-items: center
  justify-content: center
  width: 100vw
  box-shadow: 0px 1px 10px rgb(0,0,0)
  text-align: center

  img
    height: 100%
    width: auto

.formular
  background-color: var(--main-color)
  display: flex
  box-sizing: border-box
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 5vh 20vw
  min-height: 85vh

  h1
    margin-top: 0
    color: #fff

  select
    margin-bottom: 25px
    padding: 15px 25px
    border: 0
    border-radius: 6px
    font-size: 16px
    color: var(--main-color)
    background-color: #fff
    background-position: 10px 10px
    background-repeat: no-repeat
    width: 350px

  input
    margin-bottom: 25px
    padding: 15px 25px
    border: 0
    border-radius: 6px
    font-size: 16px
    color: var(--main-color)
    background-color: #fff
    background-position: 10px 10px
    background-repeat: no-repeat
    width: 300px

  .selected
    color: #fff
    font-weight: 600
    background-image: url('../assets/check_box-24px.svg')
    background-size: 24px 24px
    background-position: 0% center
    background-repeat: no-repeat
    padding-left: 7%
    margin: 1vh 30%
    cursor: pointer
    box-sizing: border-box

    a
      color: var(--accent-color) !important
      text-decoration: underline
      font-weight: 800 !important

  .notSelected
    color: #fff
    background-image: url('../assets/check_box_outline_blank-24px.svg')
    background-size: 24px 24px
    background-position: 0% center
    background-repeat: no-repeat
    padding-left: 7%
    margin: 1vh 30%
    cursor: pointer

    a
      color: var(--accent-color) !important
      text-decoration: underline
      font-weight: 600 !important

  .button
    margin-top: 4vh

.error
  color: var(--secondary-color)
  font-size: 15px
  margin-bottom: 1vh
  text-align: center
  font-weight: bold

@media (max-width: 900px)
  .main-banner
    .formular
      padding: 5vh 5vw
      flex-direction: column

      .notSelected, .selected
        margin: 1vh 10%
        padding-left: 10%
</style>