import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VueScrollTo from 'vue-scrollto'
import VueGeolocation from 'vue-browser-geolocation'
import VueNumber from 'vue-number-animation'
import VueSignaturePad from 'vue-signature-pad';

Vue.config.productionTip = false

Vue.use(VueSignaturePad);
Vue.use(VueNumber);
Vue.use(Vuelidate);
Vue.use(VueScrollTo, {
  duration: 1000,
  offset: -65
})
Vue.use(VueGeolocation);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
