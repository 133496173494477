import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AgeGate from '@/views/AgeGate.vue'
import Termeni from'@/views/Termeni'
import Politica from '@/views/Politica'
import Cookies from '@/views/Cookies'
import Graphs from '@/views/Graphs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/age',
    name: 'AgeGate',
    component: AgeGate
  },
  {
    path: '/termeni-si-conditii',
    name: 'Termeni',
    component: Termeni
  },
  {
    path: '/politica-date-personale',
    name: 'Politica',
    component: Politica
  },
  {
    path: '/politica-de-cookie-uri',
    name: 'Cookies',
    component: Cookies
  },
  {
    path: '/graphs',
    name: 'Graphs',
    component: Graphs
  },
]

const router = new VueRouter({
  routes
})

export default router
