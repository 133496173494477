<template>
  <div class="con">
    <h1>Dashboard raportare</h1>
    <!-- TODO: Add number of shit here -->
    <br /><br /><br />
    <number
      class="text-h1"
      ref="number1"
      :from="0"
      :to="flyers"
      :format="theFormat"
      :duration="3"
      :delay="0.5"
      easing="Power1.easeOut"
      v-if="flyersLoaded"
    />
    <br /><br /><br />

    <!-- Raport pe zile -->
    <h3>Raport pe zile</h3>
    <bar-graph
      style="width: 100vw"
      v-if="loaded"
      :datapoints="raport.dates"
      :label="'Cartele oferite pe zi'"
    />
    <br /><br /><br />
    <!-- Cartele activate pe loc / zi -->
    <h3>Cartele activate pe loc pe zi</h3>
    <bar-graph
      style="width: 100vw"
      v-if="loaded"
      :datapoints="raport.activate"
      :label="'Cartele oferite pe zi'"
    />
    <br /><br /><br />
    <!-- Raport pe locatii -->
    <h3>Raport pe locatii</h3>
    <bar-graph
      style="width: 100vw"
      v-if="loaded"
      :datapoints="raport.locations"
      :label="'Cartele oferite pe locatie'"
    />
    <br /><br /><br />

    <h3>Raport zilnic / locatie</h3>
    <select @change="onChange($event)">
      <option value="" disabled="disabled" selected="selected">Oras*</option>
      <option value="ALBA IULIA">ALBA IULIA</option>     
      <option  value="ARAD">ARAD</option>
      <option  value="BACAU">BACAU</option>
      <option  value="BOTOSANI">BOTOSANI</option>
      <option  value="BRAILA">BRAILA</option>
      <option  value="BRASOV">BRASOV</option>
      <option  value="BUCURESTI">BUCURESTI</option>
      <option  value="BUZAU">BUZAU</option>
      <option  value="CALARASI">CALARASI</option>
      <option  value="CLUJ">CLUJ</option>
      <option  value="CONSTANTA">CONSTANTA</option>
      <option  value="CRAIOVA">CRAIOVA</option>
      <option value="DEVA">DEVA</option>
      <option  value="DROBETA">DROBETA</option>
      <option  value="FOCSANI">FOCSANI</option>
      <option  value="GALATI">GALATI</option>
      <option  value="IASI">IASI</option>
      <option  value="ORADEA">ORADEA</option>
      <option  value="PITESTI">PITESTI</option>
      <option  value="PLOIESTI">PLOIESTI</option>
      <option value="RESITA">RESITA</option>
      <option  value="SATU MARE">SATU MARE</option>
      <option  value="SIBIU">SIBIU</option>
      <option  value="SUCEAVA">SUCEAVA</option>
      <option  value="TARGU MURES">TARGU MURES</option>
      <option  value="TARGU JIU">TARGU JIU</option>
      <option  value="TIMISOARA">TIMISOARA</option>
      <option  value="VALCEA">VALCEA</option>
      <option  value="ZALAU">ZALAU</option>
    </select>
    <br /><br /><br />

    <h3 v-if="loadedDaily">Cartele zilnic / locatie</h3>
    <bar-graph
      style="width: 100vw"
      v-if="loadedDaily"
      :datapoints="locationData.dates"
      :label="'Cartele oferite pe zi'"
    />
    <br /><br /><br />
    <h3 v-if="loadedDaily">Cartele activate pe loc / locatie</h3>
    <bar-graph
      style="width: 100vw"
      v-if="loadedDaily"
      :datapoints="locationData.activate"
      :label="'Cartele oferite pe zi'"
    />
    <br /><br /><br />
  </div>
</template>

<script>
import barGraph from "./Dashboard/GraphComponent.vue";
import axios from "axios";

export default {
  data() {
    return {
      raport: [],
      loaded: false,
      loadedDaily: false,
      options: [
        "2021-07-21",
        "2021-07-22",
        "2021-07-23",
        "2021-07-24",
        "2021-07-25",
        "2021-07-26",
        "2021-07-27",
        "2021-07-28",
        "2021-07-29",
        "2021-07-30",
        "2021-07-31",
        "2021-08-01",
        "2021-08-02",
        "2021-08-03",
        "2021-08-04",
        "2021-08-05",
        "2021-08-06",
        "2021-08-07",
        "2021-08-08",
        "2021-08-09",
        "2021-08-10",
        "2021-08-11",
        "2021-08-12",
        "2021-08-13",
        "2021-08-14",
        "2021-08-15",
        "2021-08-16",
        "2021-08-17",
        "2021-08-18",
        "2021-08-19",
        "2021-08-20",
        "2021-08-21",
        "2021-08-22",
        "2021-08-23",
        "2021-08-24",
        "2021-08-25",
        "2021-08-26",
        "2021-08-27",
        "2021-08-28",
        "2021-08-29",
        "2021-08-30",
        "2021-08-31",
        "2021-09-01",
        "2021-09-02",
        "2021-09-03",
        "2021-09-04",
        "2021-09-05",
        "2021-09-06",
      ],
      flyers: "",
      flyersLoaded: false,
      locationData: []
    };
  },
  components: {
    barGraph,
  },
  async mounted() {
    this.loaded = false;
    this.loadedDaily = false;
    try {
      const raport = await axios.get("/api/v1/raport");
      this.raport = raport.data;
      this.loaded = true;
    } catch (error) {
      console.log(error);
    }

    try {
      const raport = await axios.get("/api/v1/raport/numbers");
      this.flyers = raport.data.numbers;
      this.flyersLoaded = true;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async onChange(event) {
      this.loadedDaily = false;
      const raport = await axios.get(`/api/v1/raport/${event.target.value}`);
      this.locationData = raport.data;
      this.loadedDaily = true;
    },
    theFormat(number) {
      return number.toFixed(0) + " CARTELE OFERITE IN TOTAL".toLowerCase();
    },
  },
};
</script>

<style lang="sass" scoped>
.con
    display: flex
    width: 100vw
    flex-direction: column
    align-items: center

    h1, .text-h1
        color: var(--main-color)
        font-size: clamp(20px, 4vw, 60px)
    
    select
        margin-bottom: 25px
        padding: 15px 25px
        border: 2px solid var(--main-color)
        border-radius: 6px
        font-size: 16px
        color: var(--main-color)
        background-color: #fff
        background-position: 10px 10px
        background-repeat: no-repeat
        width: 350px
</style>